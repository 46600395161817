<template>
  <div>
    <b-card class="min-vh-100">
      <div v-if="masters.kfs.length">
        <b-row>
          <b-col
            sm="2"
            class="d-flex align-items-center"
          >
            <p class="mb-0">
              Lihat data:
            </p>
          </b-col>
          <b-col sm="4">
            <b-form-select
              v-model="config.additional_params.serviceType"
              :options="masters.filterBy"
            />
          </b-col>
          <b-col sm="3">
            <v-select
              id="tag"
              v-model="config.additional_params.kfsId"
              label="name"
              class="custom-v-select"
              placeholder="Pilih KFS"
              :clearable="false"
              :options="masters.kfs"
              :reduce="options => options.id"
            >
              <template #no-options="{ search, searching, loading }">
                Tidak ditemukan hasil pencarian
              </template>
            </v-select>
          </b-col>
          <b-col sm="3">
            <date-range-picker
              id="rangeDate"
              :date-range="vars.rangeDate"
              @update="updateDate"
            />
          </b-col>
        </b-row>
        <!-- <AppEchartBar :option-data="po.option" /> -->
        <apexchart
          width="100%"
          type="bar"
          :options="chartOptions"
          :series="series"
        />
        <div>
          <b-row>
            <b-col sm="6">
              <h3>Ringkasan</h3>
            </b-col>
          </b-row>
          <b-row
            v-for="resume, idx in resumes"
            :key="idx"
            class="border-bottom mx-3"
          >
            <b-col sm="6">
              <p class="mt-1 mb-0 font-weight-bold text-capitalize">
                {{ resume.text }}
              </p>
            </b-col>
            <b-col
              sm="6"
              class="text-right"
            >
              <p class="mt-1 mb-0 font-weight-bold">
                {{ resume.value }}
              </p>
            </b-col>
          </b-row>
        </div>
        <b-row class="mt-3">
          <ResponseAlert ref="response" />
          <div class="col-sm-12 mb-2 d-flex justify-content-between align-items-center flex-column flex-md-row">
            <div class="col-md-6 col-12 px-0 order-1 order-md-0 d-flex flex-column flex-md-row" />
            <div class="order-0 order-md-1 mb-1 mb-md-0">
              <b-button
                variant="secondary"
                class="btn-min-width rounded mr-1 full-width-responsive mb-1 mb-md-0"
                @click.prevent="exportExcel"
              >
                Download report
              </b-button>
            </div>
          </div>
          <b-col
            v-if="config.rows.length"
            sm="12"
          >
            <div class="table-responsive">
              <b-table
                id="my-table"
                :key="config.additional_params.serviceType"
                class="custom-table"
                :items="config.rows"
                :fields="tableFields"
                :per-page="config.per_page"
                :current-page="config.page"
                responsive
                style="white-space: nowrap;"
              />
              <div class="d-flex justify-content-between mt-2">
                <b-form-select
                  v-model="config.per_page"
                  :options="perPageOptions"
                  class="w-auto"
                />
                <b-pagination
                  v-model="config.page"
                  :total-rows="config.rows.length"
                  :per-page="config.per_page"
                  aria-controls="my-table"
                />
              </div>
            </div>
          </b-col>
          <b-col v-else>
            <p class="text-center py-5">
              No record data found
            </p>
          </b-col>
        </b-row>
      </div>
      <div
        v-else
        class="d-flex justify-content-center min-vh-100 align-items-center"
      >
        <h4>
          KFS Not Found, please create KFS to see reporting menu.
        </h4>
      </div>
    </b-card>

  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormSelect, BButton, BPagination, BTable,
} from 'bootstrap-vue'
import DateRangePicker from '@/layouts/components/DateRangePicker.vue'
import api from '@/utils/api'
import vSelect from 'vue-select'
import { exportExcel } from '@/utils/helpers'

export default {
  components: {
    BTable,
    BPagination,
    BButton,
    BFormSelect,
    BCard,
    BRow,
    BCol,
    DateRangePicker,
    vSelect,
  },
  metaInfo: {
    title: 'Reporting PO',
  },
  data() {
    return {
      chartOptions: {
        plotOptions: {
          bar: {
            columnWidth: '70%',
          },
        },
        noData: {
          text: 'No data found',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 0,
          offsetY: 0,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined,
          },
        },
        chart: {
          id: 'vuechart-example',
          toolbar: {
            show: true,
            offsetX: 0,
            offsetY: 0,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false,
              customIcons: [],
            },
            export: {
              csv: {
                filename: 'Reporting Chart',
                columnDelimiter: ',',
                headerCategory: 'category',
                headerValue: 'value',
                dateFormatter(timestamp) {
                  return new Date(timestamp).toDateString()
                },
              },
              svg: {
                filename: 'Reporting Chart',
              },
              png: {
                filename: 'Reporting Chart',
              },
            },
            autoSelected: 'zoom',
          },
        },
        colors: ['#B22A2E'],
        fill: {
          colors: '#B22A2E',
          gradient: {
            shadeIntensity: 1,
            opacityFrom: 0.7,
            opacityTo: 0.9,
            stops: [0, 100],
          },
        },
        dataLabels: {
          enabled: true,
          formatter(val) {
            return `${val.toLocaleString().replace(/[.,]/g, m => (m === '.' ? ',' : '.'))}`
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          labels: {
            formatter: value =>
              // Nine Zeroes for Billions
              (Math.abs(Number(value)) >= 1.0e+9
                ? `${(Math.abs(Number(value)) / 1.0e+9).toFixed(2)} Miliar`
              // Six Zeroes for Millions
                : Math.abs(Number(value)) >= 1.0e+6
                  ? `${(Math.abs(Number(value)) / 1.0e+6).toFixed(2)} Juta`
                // Three Zeroes for Thousands
                  : Math.abs(Number(value)) >= 1.0e+3
                    ? `${(Math.abs(Number(value)) / 1.0e+3).toFixed(2)} Ribu`
                    : Math.abs(Number(value)))
            ,
          },

        },
      },

      series: [{
        name: 'Sales',
        data: [],
      }],

      models: {
        summary: {},
      },
      vars: {
        rangeDate: {
          startDate: null,
          endDate: null,
        },
      },
      config: {
        additional_params: {
          startDate: null,
          endDate: null,
          kfsId: null,
          serviceType: 'by-location',
        },
        uri: this.$route.meta.link,
        api: 'reports-po',
        rows: [],
        total_data: 0,
        total_page: 0,
        per_page: 10,
        page: 1,
        orderby: 'id',
        sort: 'desc',
        search: '',
      },
      masters: {
        kfs: [],
        filterBy: [
          {
            text: 'Data Penjualan by Lokasi',
            value: 'by-location',
          },
          {
            text: 'Data Penjualan by Item',
            value: 'by-item',
          },
          {
            text: 'Data Penjualan by Type',
            value: 'by-type',
          },
          {
            text: 'Data Penjualan by Invoice Detail',
            value: 'by-invoice',
          },
        ],
      },
    }
  },
  computed: {
    currencyVariables() {
      return [
        'grossSales',
        'totalSales',
        'purchaseAmount',
        'discount',
        'netSales',
        'totalSales',
        'salesAmount',
      ]
    },
    perPageOptions() {
      return this.$store.state.app.perPageOptions
    },
    perPage() {
      return this.config.per_page
    },
    kfsId() {
      return this.config.additional_params.kfsId
    },
    serviceType() {
      return this.config.additional_params.serviceType
    },
    tableFields() {
      const fields = {
        'by-item': [
          {
            key: 'item',
            label: 'Item',
          },
          {
            key: 'qty',
            label: 'Item Sold',
          },
          {
            key: 'grossSales',
            label: 'Gross Sales',
          },
          {
            key: 'discount',
            label: 'Discount',
          },
          {
            key: 'refund',
            label: 'Refunds',
          },
          {
            key: 'netSales',
            label: 'Net Sales',
          },
          {
            key: 'tax',
            label: 'Tax',
          },
          {
            key: 'voucher',
            label: 'Sales by voucher redemption',
          },
          {
            key: 'totalSales',
            label: 'Total Sales',
          },
        ],
        'by-location': [
          {
            key: 'location',
            label: 'Location',
          },
          {
            key: 'itemPurchased',
            label: 'Item Purchased',
          },
          {
            key: 'grossSales',
            label: 'Gross Sales',
          },
          {
            key: 'totalSales',
            label: 'Total Sales',
          },
        ],
        'by-type': [
          {
            key: 'type',
            label: 'Type',
          },
          {
            key: 'itemSold',
            label: 'Item Sold',
          },
          {
            key: 'discount',
            label: 'Discount',
          },
          {
            key: 'grossSales',
            label: 'Gross Sales',
          },
          {
            key: 'netSales',
            label: 'Net Sales',
          },
          {
            key: 'refunds',
            label: 'Refunds',
          },
          {
            key: 'tax',
            label: 'Tax',
          },
          {
            key: 'voucher',
            label: 'Voucher',
          },
          {
            key: 'totalSales',
            label: 'Total Sales',
          },
        ],
        'by-invoice': [
          {
            key: 'invoiceNo',
            label: 'Invoice',
          },
          {
            key: 'date',
            label: 'Date',
          },
          {
            key: 'salesBy',
            label: 'Sales By',
          },
          {
            key: 'serveBy',
            label: 'Serve By',
          },
          {
            key: 'salesSource',
            label: 'Sales Source',
          },
          {
            key: 'salesType',
            label: 'Sales Type',
          },
          {
            key: 'salesStatus',
            label: 'Sales Status',
          },
          {
            key: 'item',
            label: 'Item',
          },
          {
            key: 'customerName',
            label: 'Customer',
          },
          {
            key: 'customerNotes',
            label: 'Customer Note',
          },
          {
            key: 'qty',
            label: 'Qty',
          },
          {
            key: 'grossSales',
            label: 'Gross Sales',
          },
          {
            key: 'discount',
            label: 'Discount',
          },
          {
            key: 'tax',
            label: 'Tax',
          },
          {
            key: 'voucher',
            label: 'Sales by voucher redemption',
          },
          {
            key: 'salesAmount',
            label: 'Sales Amount',
          },
          {
            key: 'paymentMethod',
            label: 'Payment Method',
          },
        ],
      }

      return fields[this.config.additional_params.serviceType]
    },
    additional_params() {
      return JSON.stringify(this.config.additional_params)
    },
    resumes() {
      const { summary: summaries } = this.models
      const fc = this.formatCurrency
      const rows = []
      Object.keys(summaries).forEach(sum => {
        let value = summaries[sum]

        if (this.currencyVariables.includes(sum)) {
          value = fc(summaries[sum])
        }

        rows.push({
          text: sum.replace(/([a-z])([A-Z])/g, '$1 $2'),
          value,
        })
      })
      return rows
    },
  },
  mounted() {
    this.config.additional_params.startDate = this.vars.rangeDate.startDate = this.moment().startOf('month').format('YYYY-MM-DD')
    this.config.additional_params.endDate = this.vars.rangeDate.endDate = this.moment().endOf('month').format('YYYY-MM-DD')
    this.$axios.get(`${api.list_kfs}`).then(res => {
      this.masters.kfs = [...res.data.data]
      this.config.additional_params.kfsId = this.masters.kfs[0].id

      this.$nextTick(() => {
        this.getReporting()

        this.$watch('additional_params', () => {
          this.getReporting()
        })
      })
    })
  },
  methods: {
    exportExcel() {
      const {
        serviceType, kfsId, startDate, endDate,
      } = this.config.additional_params
      const kfsName = this.masters.kfs.find(kfs => kfs.id === kfsId).name
      exportExcel(`Data Purchase Order ${serviceType} (${kfsName} ${startDate} - ${endDate})`, `${this.config.api}/excell?startDate=${startDate}&endDate=${endDate}&serviceType=${serviceType}&kfsId=${kfsId}`)
    },
    getCharts() {
      return this.$axios.get(`${this.config.api}/chart`, { params: this.config.additional_params }).then(res => {
        const { chart } = res.data.data
        const series = []
        const categories = []
        chart.forEach(value => {
          if (value.label && value.data) {
            categories.push(value.label)
            series.push(value.data)
          }
        })
        this.chartOptions = {
          ...this.chartOptions,
          ...{
            xaxis: {
              categories,
            },
          },
        }
        this.series = [{
          data: series,
        }]
      })
    },
    getReporting() {
      this.getCharts().then(() => {
        this.$axios.get(this.config.api, {
          params: this.config.additional_params,
        })
          .then(res => {
            const { rows, summary: summaries } = res.data.data
            const fc = this.formatCurrency
            this.config.rows = rows.map(row => {
              if (row.date) {
                row.date = this.moment(row.date).format('DD MMM YYYY')
              }

              if (this.config.additional_params.serviceType === 'by-invoice') {
                row.item = row.item.join(', ')
              }

              Object.keys(row).forEach(val => {
                if (this.currencyVariables.includes(val)) {
                  row[val] = fc(row[val])
                }
              })

              return row
            })

            const rowsLength = this.series[0].data.length
            const columnWidth = rowsLength > 7 ? '85%' : rowsLength > 3 ? '70%' : rowsLength > 1 ? '40%' : '20%'
            this.chartOptions = {
              ...this.chartOptions,
              ...{
                plotOptions: {
                  bar: {
                    columnWidth,
                  },
                },
              },
            }

            this.config.total_data = rows.length
            this.models.summary = summaries
          })
      })
    },
    updateDate(value) {
      const { startDate, endDate } = value
      this.config.additional_params.startDate = this.moment(startDate).format('YYYY-MM-DD')
      this.config.additional_params.endDate = this.moment(endDate).format('YYYY-MM-DD')
    },
  },
}
</script>
